export const movies = [
  { title: 'A Clockwork Orange', link: 'https://www.thisisbarry.com/film/a-clockwork-orange-ending-explained-with-plot-summary/' },
  { title: 'A Cure For Wellness', link: 'https://www.thisisbarry.com/film/a-cure-for-wellness-2016-movie-plot-ending-explained/' },
  { title: 'A Ghost Story', link: 'https://www.thisisbarry.com/film/a-ghost-story-2017-movie-plot-ending-explained/' },
  { title: 'A Quiet Place', link: 'https://www.thisisbarry.com/film/a-quiet-place-2018-movie-plot-holes-explained/' },
  { title: 'A Scanner Darkly', link: 'https://www.thisisbarry.com/film/a-scanner-darkly-2006-movie-plot-ending-explained/' },
  { title: 'A Serious Man', link: 'https://www.thisisbarry.com/film/a-serious-man-plot-and-ending-explained/' },
  { title: 'Abigail', link: 'https://www.thisisbarry.com/film/abigail-explained-what-were-the-vampire-rules/' },
  { title: 'Adaptation', link: 'https://www.thisisbarry.com/film/adaptation-movie-explained-plot-and-ending/' },
  { title: 'After Life', link: 'https://www.thisisbarry.com/film/after-life-2009-movie-plot-ending-explained/' },
  { title: 'Aladdin', link: 'https://www.thisisbarry.com/film/every-difference-between-aladdin-1992-and-2019-movies/' },
  { title: 'American Psycho', link: 'https://www.thisisbarry.com/movie/american-psycho-explained-plot-and-ending/' },
  { title: 'Anatomy Of A Fall', link: 'https://www.thisisbarry.com/film/anatomy-of-a-fall-ending-explained-what-really-happened/' },
  { title: 'Anti Matter', link: 'https://www.thisisbarry.com/film/worm-anti-matter-2017-movie-plot-ending-explained/' },
  { title: 'Annihilation', link: 'https://www.thisisbarry.com/film/annihilation-2018-movie-plot-ending-explained/' },
  { title: 'Another Earth', link: 'https://www.thisisbarry.com/film/another-earth-2011-movie-plot-ending-explained/' },
  { title: 'Archive', link: 'https://www.thisisbarry.com/film/archive-movie-explained-plot-analysis-and-ending/' },
  { title: 'ARQ', link: 'https://www.thisisbarry.com/film/arq-2016-movie-plot-ending-explained/' },
  { title: 'Arrival', link: 'https://www.thisisbarry.com/film/arrival-2016-movie-plot-ending-explained/' },
  { title: 'As Above So Below', link: 'https://www.thisisbarry.com/film/as-above-so-below-explained-plot-and-ending/' },
  { title: 'Automata', link: 'https://www.thisisbarry.com/film/automata-movie-plot-and-ending-explained/' },
  { title: 'Avengers Endgame', link: 'https://www.thisisbarry.com/film/avengers-endgame-timeline-explained-no-plot-holes/' },
  { title: 'Barbarian', link: 'https://www.thisisbarry.com/film/barbarian-movie-explained-every-question-answered/' },
  { title: 'Being John Malkovich', link: 'https://www.thisisbarry.com/film/being-john-malkovich-explained-plot-and-ending/' },
  { title: 'Better Watch Out', link: 'https://www.thisisbarry.com/film/better-watch-out-2016-movie-explained-in-short/' },
  { title: 'Bird Box', link: 'https://www.thisisbarry.com/film/bird-box-explained-movie-plot-ending/' },
  { title: 'Black Bear', link: 'https://www.thisisbarry.com/film/black-bear-movie-explained-plot-ending/' },
  { title: 'Black Box', link: 'https://www.thisisbarry.com/film/black-box-explained-2020-movie-ending-explained/' },
  { title: 'Black Swan', link: 'https://www.thisisbarry.com/film/swan-lake-black-swan-2010-movie-plot-ending-explained/' },
  { title: 'Blade Runner', link: 'https://www.thisisbarry.com/film/blade-runner-blade-runner-2049-movie-plot-ending-explained/' },
  { title: 'Bliss', link: 'https://www.thisisbarry.com/film/bliss-movie-explained-plot-analysis-and-ending/' },
  { title: 'Blonde', link: 'https://www.thisisbarry.com/film/blonde-movie-what-is-it-really-about-marilyn-monroe-biopic/' },
  { title: 'Blood Punch', link: 'https://www.thisisbarry.com/film/blood-punch-explained-and-meaning-of-the-ending/' },
  { title: 'Blow The Man Down', link: 'https://www.thisisbarry.com/film/blow-the-man-down-ending-and-full-plot-explained/' },
  { title: 'Blue Ruin', link: 'https://www.thisisbarry.com/film/blue-ruin-ending-explained/' },
  { title: 'Bottom Of The World', link: 'https://www.thisisbarry.com/film/bottom-of-the-world-explained-2017-netflix-film-analysis/' },
  { title: 'Braid', link: 'https://www.thisisbarry.com/film/braid-2018-movie-plot-and-ending-explained/' },
  { title: 'Brainstorm', link: 'https://www.thisisbarry.com/film/brainstorm-movie-explained-1983-film-plot-and-ending/' },
  { title: 'Bullet Train', link: 'https://www.thisisbarry.com/film/bullet-train-movie-explained-every-question-answered/' },
  { title: 'Buried', link: 'https://www.thisisbarry.com/film/buried-2010-movie-plot-ending-explained/' },
  { title: 'Burning', link: 'https://www.thisisbarry.com/film/burning-movie-explained-directors-views-korean-film/' },
  { title: 'Buster\'s Mal Heart', link: 'https://www.thisisbarry.com/film/busters-mal-heart-explained-what-did-it-all-mean/' },
  { title: 'Cam', link: 'https://www.thisisbarry.com/film/cam-movie-ending-explained/' },
  { title: 'Censor', link: 'https://www.thisisbarry.com/film/censor-movie-explained-plot-and-ending-explained/' },
  { title: 'Circle', link: 'https://www.thisisbarry.com/film/circle-movie-explained-2015-netflix-circle-ending-explained/' },
  { title: 'Children Of Men', link: 'https://www.thisisbarry.com/film/plot-and-ending-of-children-of-men-explained/' },
  { title: 'Clouds of Sils Maria', link: 'https://www.thisisbarry.com/film/clouds-of-sils-maria-2013-explained-what-happened-to-valentine/' },
  { title: 'Coherence', link: 'https://www.thisisbarry.com/film/coherence-2013-movie-plot-ending-explained/' },
  { title: 'Collateral Beauty', link: 'https://www.thisisbarry.com/film/collateral-beauty-2016-movie-plot-ending-explained/' },
  { title: 'Colossal', link: 'https://www.thisisbarry.com/film/colossal-2017-movie-plot-ending-explained/' },
  { title: 'Confessions', link: 'https://www.thisisbarry.com/film/kokuhaku-confessions-2010-movie-plot-ending-explained/' },
  { title: 'Confessions Of A Dangerous Mind', link: 'https://www.thisisbarry.com/film/confessions-of-a-dangerous-mind-2002-movie-explained/' },
  { title: 'Crimes Of The Future', link: 'https://www.thisisbarry.com/film/crimes-of-the-future-explained-plot-and-ending/' },
  { title: 'Cube', link: 'https://www.thisisbarry.com/film/cube-movie-explained-what-is-the-meaning/' },
  { title: 'Dark City', link: 'https://www.thisisbarry.com/film/dark-city-explained-noir-matrix-meets-george-orwell/' },
  { title: 'Deadpool', link: 'https://www.thisisbarry.com/film/deadpool-2016-movie-vs-comic-book/' },
  { title: 'Death Note', link: 'https://www.thisisbarry.com/film/death-note-live-action-movies-endings-explained/' },
  { title: 'Doctor Sleep', link: 'https://www.thisisbarry.com/film/doctor-sleep-plot-and-ending-explained/' },
  { title: 'Dogtooth', link: 'https://www.thisisbarry.com/film/dogtooth-ending-explained-with-plot-analysis/' },
  { title: 'Donnie Darko', link: 'https://www.thisisbarry.com/film/donnie-darko-2001-movie-plot-ending-explained/' },
  { title: 'Dual', link: 'https://www.thisisbarry.com/film/dual-movie-ending-who-survived-did-sarah-die/' },
  { title: 'Dunkirk', link: 'https://www.thisisbarry.com/film/dunkirk-explained-simply-plot-analysis-and-ending/' },
  { title: 'Dream House', link: 'https://www.thisisbarry.com/film/dream-house-explained-what-really-happened/' },
  { title: 'Edge Of Tomorrow', link: 'https://www.thisisbarry.com/film/edge-of-tomorrow-2014-movie-vs-book-plot-hole-explained/' },
  { title: 'Eileen', link: 'https://www.thisisbarry.com/film/eileen-movie-explained-ending-was-rebecca-real/' },
  { title: 'Elizabeth Harvest', link: 'https://www.thisisbarry.com/film/elizabeth-harvest-explained-what-is-the-film-about/' },
  { title: 'Emily The Criminal', link: 'https://www.thisisbarry.com/film/emily-the-criminal-ending-explained-in-short/' },
  { title: 'Enemy', link: 'https://www.thisisbarry.com/film/enemy-2013-movie-plot-ending-explained/' },
  { title: 'Equilibrium', link: 'https://www.thisisbarry.com/film/equilibrium-movie-explained-a-hidden-sci-fi-gem/' },
  { title: 'Eternal Sunshine of the Spotless Mind', link: 'https://www.thisisbarry.com/film/eternal-sunshine-of-the-spotless-mind-2004-movie-plot-ending-explained/' },
  { title: 'Everything Everywhere All At Once', link: 'https://www.thisisbarry.com/film/everything-everywhere-all-at-once-explained-multiverse-is-not-real/' },
  { title: 'Evil Dead Rise', link: 'https://www.thisisbarry.com/film/evil-dead-rise-explained-lily-sullivans-demonic-delight/' },
  { title: 'Evolution', link: 'https://www.thisisbarry.com/film/evolution-2015-movie-plot-ending-explained/' },
  { title: 'Ex Machina', link: 'https://www.thisisbarry.com/film/ex-machina-explained-plot-and-ending-explained/' },
  { title: 'Extinction', link: 'https://www.thisisbarry.com/film/extinction-2018-movie-plot-ending-explained/' },
  { title: 'Extracted', link: 'https://www.thisisbarry.com/film/extraction-extracted-2012-plot-ending-explained/' },
  { title: 'Extremely Loud and Incredibly Close', link: 'https://www.thisisbarry.com/film/extremely-loud-and-incredibly-close-2011-sixth-borough-explained/' },
  { title: 'Eyes Wide Shut', link: 'https://www.thisisbarry.com/film/eyes-wide-shut-explained-what-did-it-all-mean/' },
  { title: 'Fight Club', link: 'https://www.thisisbarry.com/film/fight-club-1999-movie-plot-ending-explained/' },
  { title: 'Filth', link: 'https://www.thisisbarry.com/film/filth-movie-explained-plot-and-ending-explained/' },
  { title: 'Flatliners', link: 'https://www.thisisbarry.com/film/flatliners-2017-movie-plot-ending-explained/' },
  { title: 'Fracture', link: 'https://www.thisisbarry.com/film/fracture-movie-ending-explained-2007-film/' },
  { title: 'Fractured', link: 'https://www.thisisbarry.com/film/fractured-movie-ending-explained-2019-netflix/' },
  { title: 'Frequencies', link: 'https://www.thisisbarry.com/film/frequencies-2013-movie-explained-plot-and-ending/' },
  { title: 'Fresh', link: 'https://www.thisisbarry.com/film/fresh-movie-ending-explained-in-short/' },
  { title: 'Game Over', link: 'https://www.thisisbarry.com/film/game-over-2019-ending-explained-conclusive-evidence/' },
  { title: 'Gerald\'s Game', link: 'https://www.thisisbarry.com/film/geralds-game-ending-explained-2017-movie/' },
  { title: 'Get Out', link: 'https://www.thisisbarry.com/film/get-out-2017-movie-plot-ending-explained/' },
  { title: 'Ghost In The Shell', link: 'https://www.thisisbarry.com/film/ghost-in-the-shell-2017-movie-plot-ending-explained/' },
  { title: 'Ghost Stories', link: 'https://www.thisisbarry.com/film/ghost-stories-2017-ending-explained-with-plot-analysis/' },
  { title: 'Glass', link: 'https://www.thisisbarry.com/film/glass-movie-explained-2019-film-plot-ending/' },
  { title: 'God\'s Crooked Lines', link: 'https://www.thisisbarry.com/film/gods-crooked-lines-explained-ending-was-alice-insane/' },
  { title: 'Gone Girl', link: 'https://www.thisisbarry.com/film/gone-girl-2014-movie-plot-holes-explained/' },
  { title: 'Goodnight Mommy', link: 'https://www.thisisbarry.com/film/goodnight-mommy-explained-what-happened/' },
  { title: 'Happy Death Day', link: 'https://www.thisisbarry.com/film/happy-death-day-2017-movie-plot-ending-explained/' },
  { title: 'Hatching', link: 'https://www.thisisbarry.com/film/hatching-movie-ending-explained-was-the-monster-real/' },
  { title: 'Haunter', link: 'https://www.thisisbarry.com/film/haunter-explained-movie-plot-and-ending/' },
  { title: 'Her', link: 'https://www.thisisbarry.com/film/her-2013-movie-plot-ending-explained/' },
  { title: 'Hereditary', link: 'https://www.thisisbarry.com/film/hereditary-explained-movie-ending/' },
  { title: 'Horse Girl', link: 'https://www.thisisbarry.com/film/horse-girl-ending-explained-director-netflix-movie-meaning/' },
  { title: 'House Of The Disappeared', link: 'https://www.thisisbarry.com/film/korean-movie-house-of-the-disappeared-explained/' },
  { title: 'How It Ends', link: 'https://www.thisisbarry.com/film/how-it-ends-2018-movie-explained-in-short/' },
  { title: 'Hypnotic', link: 'https://www.thisisbarry.com/film/hypnotic-movie-plot-and-ending-explained/' },
  { title: 'I Am Legend', link: 'https://www.thisisbarry.com/film/i-am-legend-explained-alternate-endings-vs-book/' },
  { title: 'I Am Mother', link: 'https://www.thisisbarry.com/film/i-am-mother-ending-explained-plot-spoiler-2019/' },
  { title: 'I Came By', link: 'https://www.thisisbarry.com/film/i-came-by-ending-explained-every-question-answered/' },
  { title: 'I Lost My Body', link: 'https://www.thisisbarry.com/film/i-lost-my-body-explained-simply-2019-netflix/' },
  { title: 'I Origins', link: 'https://www.thisisbarry.com/film/i-origins-2014-movie-plot-ending-explained/' },
  { title: 'I\'m Thinking Of Ending Things', link: 'https://www.thisisbarry.com/film/simplified-explanation-of-im-thinking-of-ending-things/' },
  { title: 'Identity', link: 'https://www.thisisbarry.com/film/identity-movie-explained-what-really-happened/' },
  { title: 'In The Shadow Of The Moon', link: 'https://www.thisisbarry.com/film/in-the-shadow-of-the-moon-explained-2019-netflix/' },
  { title: 'Inception', link: 'https://www.thisisbarry.com/film/inception-2010-movie-plot-ending-explained/' },
  { title: 'Infinity Chamber', link: 'https://www.thisisbarry.com/film/somnio-infinity-chamber-2016-movie-plot-ending-explained/' },
  { title: 'Infinity Pool', link: 'https://www.thisisbarry.com/film/infinity-pool-explained-plot-and-ending/' },
  { title: 'Interstellar', link: 'https://www.thisisbarry.com/film/interstellar-2014-movie-plot-holes-explained/' },
  { title: 'Invisible Guest', link: 'https://www.thisisbarry.com/film/contratiempo-invisible-guest-2016-movie-plot-ending-explained/' },
  { title: 'Irreversible', link: 'https://www.thisisbarry.com/film/irreversible-2002-movie-plot-ending-explained/' },
  { title: 'It Comes At Night', link: 'https://www.thisisbarry.com/film/it-comes-at-night-explained-ending-plot-analysis/' },
  { title: 'It Follows', link: 'https://www.thisisbarry.com/film/it-follows-explained-plot-analysis-and-ending/' },
  { title: 'It\'s Such A Beautiful Day', link: 'https://www.thisisbarry.com/film/its-such-a-beautiful-day-movie-explained-a-short-analysis/' },
  { title: 'John Wick', link: 'https://www.thisisbarry.com/film/john-wick-2014-movie-plot-holes-explained/' },
  { title: 'Joker', link: 'https://www.thisisbarry.com/film/joker-ending-explained-2019-movie/' },
  { title: 'Jonathan', link: 'https://www.thisisbarry.com/film/jonathan-2018-movie-ending-explained/' },
  { title: 'Julia�s Eyes', link: 'https://www.thisisbarry.com/film/los-ojos-de-julia-julias-eyes-2010-movie-plot-ending-explained/' },
  { title: 'Jumanji', link: 'https://www.thisisbarry.com/film/how-are-the-jumanji-movies-connected/' },
  { title: 'Jupiter Ascending', link: 'https://www.thisisbarry.com/film/jupiter-ascending-2015-movie-plot-holes-explained/' },
  { title: 'Kill List', link: 'https://www.thisisbarry.com/film/kill-list-2011-movie-plot-ending-explained/' },
  { title: 'Kung Fu Killer', link: 'https://www.thisisbarry.com/film/kung-fu-killer-2014-movie-plot-review/' },
  { title: 'Last Night In Soho', link: 'https://www.thisisbarry.com/film/last-night-in-soho-ending-explained/' },
  { title: 'Let the Right One In', link: 'https://www.thisisbarry.com/film/let-the-right-one-in-2008-swedish-ending-explained/' },
  { title: 'Life', link: 'https://www.thisisbarry.com/film/life-2017-movie-plot-ending-explained/' },
  { title: 'Little Fish', link: 'https://www.thisisbarry.com/film/little-fish-ending-explained-2020-movie/' },
  { title: 'Logan', link: 'https://www.thisisbarry.com/film/logan-2017-movie-plot-ending-explained/' },
  { title: 'Looper', link: 'https://www.thisisbarry.com/film/looper-2012-movie-plot-ending-explained/' },
  { title: 'Loveless', link: 'https://www.thisisbarry.com/film/nelyubov-loveless-2017-movie-ending-explained/' },
  { title: 'Loving Adults', link: 'https://www.thisisbarry.com/film/loving-adults-ending-explained-plot-and-theories/' },
  { title: 'Lucy', link: 'https://www.thisisbarry.com/film/lucy-2014-movie-plot-ending-explained/' },
  { title: 'Magnolia', link: 'https://www.thisisbarry.com/film/magnolia-1999-movie-plot-ending-explained/' },
  { title: 'Malignant', link: 'https://www.thisisbarry.com/film/malignant-movie-explained-plot-and-ending/' },
  { title: 'Martyrs', link: 'https://www.thisisbarry.com/film/martyrs-2008-movie-plot-ending-explained/' },
  { title: 'Melancholia', link: 'https://www.thisisbarry.com/film/melancholia-2011-movie-plot-ending-explained/' },
  { title: 'Memento', link: 'https://www.thisisbarry.com/film/memento-2000-movie-plot-simplified-ending-explained/' },
  { title: 'Men', link: 'https://www.thisisbarry.com/film/men-movie-explained-full-plot-and-ending/' },
  { title: 'Midnight Special', link: 'https://www.thisisbarry.com/film/midnight-special-explained-plot-and-ending/' },
  { title: 'Midsommar', link: 'https://www.thisisbarry.com/film/midsommar-explained-2019-film-what-is-it-about/' },
  { title: 'Mine Games', link: 'https://www.thisisbarry.com/film/mine-games-explained-movie-plot-and-ending/' },
  { title: 'Minor Premise', link: 'https://www.thisisbarry.com/film/minor-premise-ending-explained-with-movie-walkthrough/' },
  { title: 'Mirage', link: 'https://www.thisisbarry.com/film/mirage-movie-explained-durante-la-tormenta-2018/' },
  { title: 'Money Monster', link: 'https://www.thisisbarry.com/film/money-monster-2016-movie-plot-ending-explained/' },
  { title: 'Moon', link: 'https://www.thisisbarry.com/film/moon-2009-movie-plot-ending-explained/' },
  { title: 'Morgan', link: 'https://www.thisisbarry.com/film/morgan-2016-movie-plot-ending-explained/' },
  { title: 'Mother', link: 'https://www.thisisbarry.com/film/madeo-mother-2009-korean-movie-ending-explained/' },
  { title: 'Mother', link: 'https://www.thisisbarry.com/film/mother-2017-movie-plot-ending-explained/' },
  { title: 'Mr Nobody', link: 'https://www.thisisbarry.com/movie/mr-nobody-plot-and-ending-explained/' },
  { title: 'Mulholland Drive', link: 'https://www.thisisbarry.com/film/mulholland-drive-explained-simply-with-character-map/' },
  { title: 'Murder By Numbers', link: 'https://www.thisisbarry.com/film/murder-by-numbers-2002-movie-plot-ending-explained/' },
  { title: 'Murder On The Orient Express', link: 'https://www.thisisbarry.com/film/murder-on-the-orient-express-ending-explained-2017-movie-illustration/' },
  { title: 'Nefarious', link: 'https://www.thisisbarry.com/movie/nefarious-2023-ending-explained-with-full-plot/' },
  { title: 'Nightcrawler', link: 'https://www.thisisbarry.com/film/nightcrawler-ending-explained-full-plot-analysis/' },
  { title: 'No Country For Old Men', link: 'https://www.thisisbarry.com/film/no-country-for-old-men-ending-explained/' },
  { title: 'No One Will Save You', link: 'https://www.thisisbarry.com/film/no-one-will-save-you-ending-explained-full-plot-analysis/' },
  { title: 'Nocturnal Animals', link: 'https://www.thisisbarry.com/film/nocturnal-animals-2016-movie-plot-ending-explained/' },
  { title: 'Nocturne', link: 'https://www.thisisbarry.com/film/nocturne-ending-explained-2020-movie/' },
  { title: 'Nope', link: 'https://www.thisisbarry.com/film/nope-meaning-plot-of-nope-and-ending-explained/' },
  { title: 'Oculus', link: 'https://www.thisisbarry.com/film/oculus-2013-movie-plot-ending-explained/' },
  { title: 'Old', link: 'https://www.thisisbarry.com/film/old-movie-ending-explained-with-plot-analysis/' },
  { title: 'One Day', link: 'https://www.thisisbarry.com/movie/one-day-why-it-stands-out-in-the-sea-of-mediocre-romantic-films/' },
  { title: 'Other Life', link: 'https://www.thisisbarry.com/film/otherlife-2017-movie-plot-ending-explained/' },
  { title: 'Oxygen', link: 'https://www.thisisbarry.com/film/oxygen-movie-ending-explained-with-plot-walkthrough/' },
  { title: 'Palm Springs', link: 'https://www.thisisbarry.com/film/palm-springs-plot-and-ending-explained-dinosaurs-too/' },
  { title: 'Parallel', link: 'https://www.thisisbarry.com/film/parallel-movie-explained-plot-holes-and-fixes/' },
  { title: 'Parasite', link: 'https://www.thisisbarry.com/film/parasite-ending-explained-2019-korean-film-gisaengchung/' },
  { title: 'Passengers', link: 'https://www.thisisbarry.com/film/passengers-2016-movie-plot-ending-explained/' },
  { title: 'Possession', link: 'https://www.thisisbarry.com/film/possession-1981-explained-a-controversial-masterpiece/' },
  { title: 'Possessor', link: 'https://www.thisisbarry.com/film/possessor-ending-explained-with-plot-walkthrough/' },
  { title: 'Predestination', link: 'https://www.thisisbarry.com/film/predestination-2014-movie-plot-ending-explained/' },
  { title: 'Primer', link: 'https://www.thisisbarry.com/film/primer-2004-movie-plot-ending-explained/' },
  { title: 'Project Almanac', link: 'https://www.thisisbarry.com/film/project-almanac-2015-movie-plotholes-ending-explained/' },
  { title: 'Promising Young Woman', link: 'https://www.thisisbarry.com/film/promising-young-woman-ending-explained/' },
  { title: 'Red Lights', link: 'https://www.thisisbarry.com/film/red-lights-2012-movie-plot-ending-explained/' },
  { title: 'Rememory', link: 'https://www.thisisbarry.com/film/rememory-explained-in-detail-who-killed-gordon/' },
  { title: 'Reminiscence', link: 'https://www.thisisbarry.com/film/reminiscence-ending-explained-full-plot-walkthrough/' },
  { title: 'Resolution', link: 'https://www.thisisbarry.com/film/resolution-2012-movie-explained-the-endless-2017-prequel/' },
  { title: 'Return To Sender', link: 'https://www.thisisbarry.com/film/return-to-sender-ending-explained-in-short/' },
  { title: 'Revolver', link: 'https://www.thisisbarry.com/film/revolver-movie-explained-2005-film-detailed-analysis/' },
  { title: 'Ruby Sparks', link: 'https://www.thisisbarry.com/film/ruby-sparks-2012-movie-ending-explained/' },
  { title: 'Run', link: 'https://www.thisisbarry.com/film/run-movie-explained-plot-and-ending/' },
  { title: 'Scarlet Innocence', link: 'https://www.thisisbarry.com/film/scarlet-innocence-2014-movie-plot-ending-explained/' },
  { title: 'Seven', link: 'https://www.thisisbarry.com/movie/se7en-seven-ending-explained-a-detailed-plot-analysis/' },
  { title: 'Shimmer Lake', link: 'https://www.thisisbarry.com/film/shimmer-lake-explained-in-chronological-order/' },
  { title: 'Shirley', link: 'https://www.thisisbarry.com/film/shirley-movie-ending-explained/' },
  { title: 'Shutter Island', link: 'https://www.thisisbarry.com/film/shutter-island-2010-movie-plot-ending-explained/' },
  { title: 'Side Effects', link: 'https://www.thisisbarry.com/film/side-effects-2013-movie-plot-ending-explained/' },
  { title: 'Sleepless Night', link: 'https://www.thisisbarry.com/film/nuit-blanche-sleepless-night-2011-movie-plot-explained/' },
  { title: 'Smile', link: 'https://www.thisisbarry.com/film/smile-movie-ending-explained-all-questions-answered/' },
  { title: 'Snowpiercer', link: 'https://www.thisisbarry.com/film/snowpiercer-2013-movie-plot-ending-explained/' },
  { title: 'Sound Of My Voice', link: 'https://www.thisisbarry.com/film/sound-of-my-voice-explained-is-maggie-a-time-traveller/' },
  { title: 'Source Code', link: 'https://www.thisisbarry.com/film/source-code-2011-movie-plot-ending-explained/' },
  { title: 'Southpaw', link: 'https://www.thisisbarry.com/film/southpaw-2015-movie-plot-hole-explained/' },
  { title: 'Split', link: 'https://www.thisisbarry.com/film/split-2017-movie-plot-ending-explained-unbreakable-2000/' },
  { title: 'Starfish', link: 'https://www.thisisbarry.com/movie/starfish-movie-recommendation-and-explanation/' },
  { title: 'Stay', link: 'https://www.thisisbarry.com/film/stay-2005-movie-plot-explained-explained/' },
  { title: 'Submergence', link: 'https://www.thisisbarry.com/film/submergence-2017-movie-explained-in-short/' },
  { title: 'Super', link: 'https://www.thisisbarry.com/film/super-2010-movie-explained-in-short/' },
  { title: 'Suspiria', link: 'https://www.thisisbarry.com/film/suspiria-ending-explained-2018-suspiria-explained/' },
  { title: 'Swallow', link: 'https://www.thisisbarry.com/film/swallow-movie-explained-what-is-the-movie-about/' },
  { title: 'Synchronic', link: 'https://www.thisisbarry.com/film/synchronic-movie-explained-plot-and-ending-explained/' },
  { title: 'Synchronicity', link: 'https://www.thisisbarry.com/film/synchronicity-movie-explained-full-plot-and-ending/' },
  { title: 'Take Shelter', link: 'https://www.thisisbarry.com/film/take-shelter-2011-plot-ending-explained/' },
  { title: 'Talk To Me', link: 'https://www.thisisbarry.com/film/talk-to-me-explained-ending-and-every-question-answered/' },
  { title: 'Tenet', link: 'https://www.thisisbarry.com/film/tenet-explained-in-detail-with-timeline-diagrams/' },
  { title: 'The Adam Project', link: 'https://www.thisisbarry.com/film/the-adam-project-plot-and-ending-explained-timeline-diagram/' },
  { title: 'The Age of Adaline', link: 'https://www.thisisbarry.com/film/the-age-of-adaline-2015-movie-explained-in-short/' },
  { title: 'The Autopsy Of Jane Doe', link: 'https://www.thisisbarry.com/film/the-autopsy-of-jane-doe-2016-movie-plot-ending-explained/' },
  { title: 'The Awakening', link: 'https://www.thisisbarry.com/film/the-awakening-ending-explained-2011-film-plot-analysis/' },
  { title: 'The Babysitter', link: 'https://www.thisisbarry.com/film/the-babysitter-2017-movie-explained-in-short/' },
  { title: 'The Black Phone', link: 'https://www.thisisbarry.com/film/the-black-phone-explained-every-question-answered/' },
  { title: 'The Cabin In The Woods', link: 'https://www.thisisbarry.com/film/cabin-in-the-woods-ending-explained/' },
  { title: 'The Call', link: 'https://www.thisisbarry.com/film/korean-movie-the-call-ending-explained-2020-netflix/' },
  { title: 'The Cloverfield Paradox', link: 'https://www.thisisbarry.com/film/the-cloverfield-paradox-2018-movie-plot-ending-explained/' },
  { title: 'The Devil\�s Advocate', link: 'https://www.thisisbarry.com/film/the-devils-advocate-1997-movie-plot-ending-explained/' },
  { title: 'The Discovery', link: 'https://www.thisisbarry.com/film/the-discovery-2017-movie-plot-ending-explained/' },
  { title: 'The Door Into Summer', link: 'https://www.thisisbarry.com/film/the-door-into-summer-explained-japanese-film/' },
  { title: 'The Drop', link: 'https://www.thisisbarry.com/film/the-drop-2014-movie-plot-ending-explained/' },
  { title: 'The Empty Man', link: 'https://www.thisisbarry.com/film/the-empty-man-explained-plot-and-ending/' },
  { title: 'The Endless', link: 'https://www.thisisbarry.com/film/the-endless-2017-explained-connection-to-resolution-2012/' },
  { title: 'The Fare', link: 'https://www.thisisbarry.com/film/the-fare-movie-explained-what-actually-happened/' },
  { title: 'The Fountain', link: 'https://www.thisisbarry.com/film/the-fountain-explained-what-was-it-all-about/' },
  { title: 'The Gift', link: 'https://www.thisisbarry.com/film/the-gift-2015-movie-plot-ending-explained/' },
  { title: 'The Good Liar', link: 'https://www.thisisbarry.com/film/the-good-liar-ending-explained-book-vs-movie/' },
  { title: 'The Guilty', link: 'https://www.thisisbarry.com/film/the-guilty-ending-explained-all-questions-answered/' },
  { title: 'The Half of It', link: 'https://www.thisisbarry.com/movie/the-half-of-it-identifying-oneself-before-finding-the-better-half/' },
  { title: 'The Handmaiden', link: 'https://www.thisisbarry.com/film/ah-ga-ssi-the-handmaiden-2016-movie-plot-ending-explained/' },
  { title: 'The Head Hunter', link: 'https://www.thisisbarry.com/film/the-head-hunter-ending-explained-2019-film/' },
  { title: 'The Host', link: 'https://www.thisisbarry.com/movie/the-host-explanation-and-analysis-2006-korean-film/' },
  { title: 'The House That Jack Built', link: 'https://www.thisisbarry.com/film/the-house-that-jack-built-ending-explained/' },
  { title: 'The Hunt', link: 'https://www.thisisbarry.com/film/jagten-the-hunt-2012-ending-explained/' },
  { title: 'The Infinite Man', link: 'https://www.thisisbarry.com/film/the-infinite-man-ending-explained-2014-film/' },
  { title: 'The Incident', link: 'https://www.thisisbarry.com/film/the-incident-movie-explained-what-did-it-all-mean/' },
  { title: 'The Invisible', link: 'https://www.thisisbarry.com/film/the-invisible-2007-movie-plot-ending-explained/' },
  { title: 'The Invitation', link: 'https://www.thisisbarry.com/film/the-invitation-2015-movie-explained-in-short/' },
  { title: 'The Jacket', link: 'https://www.thisisbarry.com/film/the-jacket-explained-2005-movie-plot-ending/' },
  { title: 'The Killing Of A Sacred Deer', link: 'https://www.thisisbarry.com/film/the-killing-of-a-sacred-deer-ending-explained/' },
  { title: 'The Lie', link: 'https://www.thisisbarry.com/film/the-lie-ending-explained-2018-movie/' },
  { title: 'The Lighthouse', link: 'https://www.thisisbarry.com/film/the-lighthouse-explained-what-actually-happened/' },
  { title: 'The Little Things', link: 'https://www.thisisbarry.com/film/the-little-things-ending-explained-with-plot-summary/' },
  { title: 'The Lobster', link: 'https://www.thisisbarry.com/film/the-lobster-explained-film-analysis-and-ending-explained/' },
  { title: 'The Lodge', link: 'https://www.thisisbarry.com/film/the-lodge-ending-explained-plot-analysis/' },
  { title: 'The Map Of Tiny Perfect Things', link: 'https://www.thisisbarry.com/film/the-map-of-tiny-perfect-things-ending-explained//' },
  { title: 'The Martian', link: 'https://www.thisisbarry.com/film/the-martian-2015-movie-plot-ending-explained/' },
  { title: 'The Matrix', link: 'https://www.thisisbarry.com/film/the-matrix-1999-movie-plot-simplified-ending-explained/' },
  { title: 'The Matrix Reloaded', link: 'https://www.thisisbarry.com/film/the-matrix-reloaded-2003-movie-plot-simplified-ending-explained/' },
  { title: 'The Matrix Revolutions', link: 'https://www.thisisbarry.com/film/the-matrix-revolutions-2003-movie-plot-simplified-ending-explained/' },
  { title: 'The Matrix Resurrections', link: 'https://www.thisisbarry.com/film/the-matrix-resurrections-explained-plot-and-ending/' },
  { title: 'The Menu', link: 'https://www.thisisbarry.com/film/the-menu-movie-explained-every-question-answered/' },
  { title: 'The Neon Demon', link: 'https://www.thisisbarry.com/film/the-neon-demon-explained-what-is-it-about/' },
  { title: 'The Nice Guys', link: 'https://www.thisisbarry.com/film/the-nice-guys-2016-movie-plot-ending-explained/' },
  { title: 'The Night House', link: 'https://www.thisisbarry.com/film/the-night-house-explained-two-possible-explanations/' },
  { title: 'The One I Love', link: 'https://www.thisisbarry.com/film/the-one-i-love-plot-explained/' },
  { title: 'The Outfit', link: 'https://www.thisisbarry.com/film/the-outfit-full-plot-and-ending-explained/' },
  { title: 'The Pale Blue Eye', link: 'https://www.thisisbarry.com/film/the-pale-blue-eye-ending-explained-who-is-the-killer/' },
  { title: 'The Platform', link: 'https://www.thisisbarry.com/film/the-platform-movie-ending-explained-2019-netflix-film/' },
  { title: 'The Power Of The Dog', link: 'https://www.thisisbarry.com/film/the-power-of-the-dog-ending-explained/' },
  { title: 'The Prestige', link: 'https://www.thisisbarry.com/film/the-prestige-explained-ending-and-detailed-plot-analysis/' },
  { title: 'The Room', link: 'https://www.thisisbarry.com/film/the-room-2019-movie-ending-and-plot-explained/' },
  { title: 'The Secret In Their Eyes', link: 'https://www.thisisbarry.com/film/the-secret-in-their-eyes-2009-spanish-movie-plot-ending-explained/' },
  { title: 'The Shining', link: 'https://www.thisisbarry.com/film/the-shining-explained-simply-plot-and-ending/' },
  { title: 'The Sixth Sense', link: 'https://www.thisisbarry.com/film/the-sixth-sense-1999-movie-plot-ending-explained/' },
  { title: 'The Skin I Live In', link: 'https://www.thisisbarry.com/film/the-skin-i-live-in-ending-explained-2011-spanish-film/' },
  { title: 'The Thinning', link: 'https://www.thisisbarry.com/film/the-thinning-2016-movie-plot-ending-explained/' },
  { title: 'The Thirteenth Floor', link: 'https://www.thisisbarry.com/film/the-thirteenth-floor-1999-movie-plot-ending-explained/' },
  { title: 'The Tomorrow War', link: 'https://www.thisisbarry.com/film/the-tomorrow-war-explained-with-timeline-diagrams/' },
  { title: 'The Usual Suspects', link: 'https://www.thisisbarry.com/film/the-usual-suspects-explained/' },
  { title: 'The Vast Of Night', link: 'https://www.thisisbarry.com/film/the-vast-of-night-explained-with-directors-views/' },
  { title: 'The Visit', link: 'https://www.thisisbarry.com/film/the-visit-explained-plot-and-ending/' },
  { title: 'The Wailing', link: 'https://www.thisisbarry.com/film/gokseong-the-wailing-2016-movie-plot-ending-explained/' },
  { title: 'The Wind', link: 'https://www.thisisbarry.com/film/the-wind-explained-2018-film-ending-explained/' },
  { title: 'The Witch', link: 'https://www.thisisbarry.com/film/the-witch-explained-the-vvitch-plot-and-ending/' },
  { title: 'The Zero Theorem', link: 'https://www.thisisbarry.com/film/the-zero-theorem-2013-movie-plot-ending-explained/' },
  { title: 'There Will Be Blood', link: 'https://www.thisisbarry.com/film/there-will-be-blood-explained-plot-and-ending/' },
  { title: 'Thoroughbreds', link: 'https://www.thisisbarry.com/film/thoroughbreds-ending-explained-in-short-2017-movie/' },
  { title: 'Timecrimes', link: 'https://www.thisisbarry.com/film/los-cronocrimenes-timecrimes-2007-movie-plot-ending-explained/' },
  { title: 'Time Lapse', link: 'https://www.thisisbarry.com/film/time-lapse-2014-movie-plot-ending-explained/' },
  { title: 'Time Trap', link: 'https://www.thisisbarry.com/film/time-trap-ending-explained-with-detailed-plot-analysis/' },
  { title: 'Tinker Tailor Soldier Spy', link: 'https://www.thisisbarry.com/film/tinker-tailor-soldier-spy-2011-movie-plot-ending-explained/' },
  { title: 'Tomb Raider', link: 'https://www.thisisbarry.com/film/tomb-raider-2018-movie-explained-in-short/' },
  { title: 'Trance', link: 'https://www.thisisbarry.com/film/trance-2013-movie-explained-plot-and-ending-explained/' },
  { title: 'Triangle', link: 'https://www.thisisbarry.com/film/triangle-2009-movie-plot-ending-explained/' },
  { title: 'Truth Or Dare', link: 'https://www.thisisbarry.com/film/truth-or-dare-2018-movie-plot-ending-explained/' },
  { title: 'Twelve Monkeys', link: 'https://www.thisisbarry.com/film/twelve-monkeys-12-monkeys-1995-plot-ending-explained/' },
  { title: 'Ultrasound', link: 'https://www.thisisbarry.com/film/ultrasound-movie-explained-plot-and-ending/' },
  { title: 'Under The Skin', link: 'https://www.thisisbarry.com/film/under-the-skin-2013-movie-plot-ending-explained/' },
  { title: 'Underground', link: 'https://www.thisisbarry.com/film/underground-1995-movie-magical-realism-in-cinematography/' },
  { title: 'Upgrade', link: 'https://www.thisisbarry.com/film/upgrade-movie-ending-explained-with-plot-synopsis/' },
  { title: 'Upside Down', link: 'https://www.thisisbarry.com/film/upside-down-movie-explained-plot-holes-discussed/' },
  { title: 'Upstream Color', link: 'https://www.thisisbarry.com/film/upstream-color-2013-movie-plot-ending-explained/' },
  { title: 'Us', link: 'https://www.thisisbarry.com/film/us-movie-explained-2019-film-us-explained/' },
  { title: 'V For Vendetta', link: 'https://www.thisisbarry.com/film/v-for-vendetta-2006-movie-vs-graphic-novel-explained/' },
  { title: 'Vanilla Sky', link: 'https://www.thisisbarry.com/film/vanilla-sky-2001-movie-plot-ending-explained/' },
  { title: 'Velvet Buzzsaw', link: 'https://www.thisisbarry.com/film/velvet-buzzsaw-plot-ending-explained/' },
  { title: 'Vivarium', link: 'https://www.thisisbarry.com/film/vivarium-movie-explained-what-is-the-meaning-of-it-all/' },
  { title: 'What Happened To Monday', link: 'https://www.thisisbarry.com/film/what-happened-to-monday-2017-movie-plot-ending-explained/' },
  { title: 'Where The Crawdads Sing', link: 'https://www.thisisbarry.com/film/where-the-crawdads-sing-ending-explained/' },
  { title: 'White Noise', link: 'https://www.thisisbarry.com/film/white-noise-movie-explained-plot-and-ending/' },
  { title: 'Who Am I', link: 'https://www.thisisbarry.com/film/who-am-i-plot-and-ending-explained-2014-netflix-film/' },
  { title: 'You Should Have Left', link: 'https://www.thisisbarry.com/film/you-should-have-left-explained-plot-and-ending/' },
  { title: 'Your Name', link: 'https://www.thisisbarry.com/film/kimi-no-na-wa-your-name-2016-movie-plot-ending-explained/' }
];

import React from 'react';
import styled from 'styled-components';

const StickmanWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  width: 100px; /* Set the overall width to make it mobile-friendly */
  height: 200px; /* Set the overall height */
  margin: 0 auto; /* Center the stickman */
`;

const Pedestal = styled.div`
  position: absolute;
  top: 0;
  width: 100px;
  height: 10px;
  background-color: black;
`;

const Rope = styled.div`
  position: absolute;
  top: 10px; /* Move the rope just below the pedestal */
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 50px;
  background-color: black;
`;

const BodyPart = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
`;

const Head = styled(BodyPart)`
  top: 60px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const Body = styled(BodyPart)`
  top: 85px;
  width: 2px;
  height: 40px;
`;

/* Adjusted positions for the arms and legs using absolute positioning */

const LeftArm = styled.div`
  position: absolute;
  top: 95px; /* Align with the body */
  left: calc(50% - 35px); /* Move it to the left of the body */
  width: 35px;
  height: 2px;
  background-color: black;
  transform: rotate(-45deg); /* Rotate to create a diagonal */
`;

const RightArm = styled.div`
  position: absolute;
  top: 95px; /* Align with the body */
  left: 50%; /* Align with the body */
  width: 35px;
  height: 2px;
  background-color: black;
  transform: rotate(45deg); /* Rotate to create a diagonal */
`;

const LeftLeg = styled.div`
  position: absolute;
  top: 140px; /* Align with the bottom of the body */
  left: calc(50% - 40px); /* Move it to the left of the body */
  width: 40px;
  height: 2px;
  background-color: black;
  transform: rotate(-45deg); /* Rotate to create a diagonal */
`;

const RightLeg = styled.div`
  position: absolute;
  top: 140px; /* Align with the bottom of the body */
  left: 50%; /* Align with the body */
  width: 40px;
  height: 2px;
  background-color: black;
  transform: rotate(45deg); /* Rotate to create a diagonal */
`;

const Stickman = ({ wrongGuesses }) => {
  return (
    <StickmanWrapper>
      {/* Pedestal always at the top */}
      <Pedestal />
      {/* Rope below the pedestal */}
      <Rope />

      {/* Parts of the stickman revealed based on wrong guesses */}
      {wrongGuesses >= 1 && <Head />}
      {wrongGuesses >= 2 && <Body />}
      {wrongGuesses >= 3 && <LeftArm />} {/* Left arm */}
      {wrongGuesses >= 4 && <RightArm />} {/* Right arm */}
      {wrongGuesses >= 5 && <LeftLeg />} {/* Left leg */}
      {wrongGuesses >= 6 && <RightLeg />} {/* Right leg */}
    </StickmanWrapper>
  );
};

export default Stickman;

import React from 'react';
import styled from 'styled-components';

const Key = styled.button`
  margin: 5px;
  padding: 10px 15px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 40px;
  height: 50px;
  text-align: center;
  text-transform: uppercase; /* Ensure keys are always displayed as uppercase */

  &:disabled {
    background-color: #ccc;
  }

  @media (max-width: 600px) {
    font-size: 1rem;
    width: 30px;
    height: 40px;
    padding: 5px 10px;
  }

  @media (max-width: 400px) {
    font-size: 0.8rem;
    width: 25px;
    height: 35px;
    padding: 5px;
  }
`;

const KeyboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    margin-bottom: 5px;
  }

  @media (max-width: 400px) {
    margin-bottom: 3px;
  }
`;

const Keyboard = ({ guessedLetters, handleGuess }) => {
  const qwertyLayout = [
    'qwertyuiop'.split(''),
    'asdfghjkl'.split(''),
    'zxcvbnm'.split('')
  ];

  return (
    <KeyboardWrapper>
      {qwertyLayout.map((row, rowIndex) => (
        <Row key={rowIndex}>
          {row.map((letter) => (
            <Key
              key={letter}
              onClick={() => handleGuess(letter)}
              disabled={guessedLetters.includes(letter)}
            >
              {letter.toUpperCase()}
            </Key>
          ))}
        </Row>
      ))}
    </KeyboardWrapper>
  );
};

export default Keyboard;

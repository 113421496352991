import React from 'react';
import styled from 'styled-components';

const MovieDisplay = styled.div`
  font-size: 2em;
  letter-spacing: 0.2em;
  word-break: break-word; /* Allow breaking between words but not within */
  text-transform: uppercase;
  margin: 30px auto;
  max-width: 80%; /* Limits the width for proper wrapping */
`;

const WordWrapper = styled.span`
  display: inline-block;
  white-space: nowrap; /* Prevent splitting dashes across lines */
  margin-right: 0.5em; /* Add spacing between words */
`;

function GameBoard({ guessedLetters, movie }) {
  const displayMovie = movie.split(' ').map((word, index) => (
    <WordWrapper key={index}>
      {word.split('').map((letter, i) => {
        if (letter === "'") {
          return "'"; // Show apostrophes automatically
        }
        return guessedLetters.includes(letter.toLowerCase()) ? letter : '_'; // Display guessed letters or dashes
      }).join('')}
    </WordWrapper>
  ));

  return <MovieDisplay>{displayMovie}</MovieDisplay>;
}

export default GameBoard;

import React, { useState, useEffect } from 'react';
import GameBoard from './components/GameBoard';
import Keyboard from './components/Keyboard';
import { movies } from './components/MovieList';
import styled from 'styled-components';
import Stickman from './components/Stickman';

const AppWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Logo = styled.img`
  max-width: 200px;
  width: 100%;
`;

const LinkWrapper = styled.div`
  margin: 5px 0;
`;

const StatsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 0px;
`;

const Stat = styled.div`
  text-align: center;
  font-size: 2em;
  span {
    display: block;
    font-size: 0.5em;
    color: gray;
  }
`;

const ExplanationText = styled.div`
  margin-top: 20px;
  font-size: 1.2em;
  color: #1DA299;
  font-weight: 500;
`;

const ExplanationLink = styled.a`
  color: blue;
  text-decoration: underline;
`;

const CountdownWrapper = styled.div`
  margin-top: 10px;
  font-size: 0.9em;
  color: red;
`;

const ShareButton = styled.button`
  margin-top: 20px;
  background-color: #1DA299;
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

function App() {
  const [movieObj, setMovieObj] = useState({ title: '', link: '' });
  const [guessedLetters, setGuessedLetters] = useState([]);
  const [remainingGuesses, setRemainingGuesses] = useState(6);
  const [gameEnded, setGameEnded] = useState(false);
  const [countdown, setCountdown] = useState('');
  const [gameWasLoaded, setGameWasLoaded] = useState(false); // NEW: To track if the game was loaded from localStorage

  const getInitialStat = (key, defaultValue) => {
    const savedStat = localStorage.getItem(key);
    return savedStat ? parseInt(savedStat, 10) : defaultValue;
  };

  const [totalTries, setTotalTries] = useState(() => getInitialStat('totalTries', 0));
  const [currentStreak, setCurrentStreak] = useState(() => getInitialStat('currentStreak', 0));
  const [bestStreak, setBestStreak] = useState(() => getInitialStat('bestStreak', 0));

  const isGameWon = movieObj.title.split('').every((letter) => {
    return guessedLetters.includes(letter) || letter === ' ' || letter === "'";
  });
  const isGameLost = remainingGuesses <= 0;

  // Function to calculate time left until midnight
  const calculateCountdown = () => {
    const now = new Date();
    const nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0); // Set to midnight
    const timeLeft = nextMidnight - now; // Difference in milliseconds

    const hours = Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0');
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  // Function to select movie based on the current date (day of the year)
  const getMovieOfTheDay = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), 0, 0); // Start of the year
    const diff = today - start; // Difference in milliseconds
    const oneDay = 1000 * 60 * 60 * 24; // Milliseconds in a day
    const dayOfYear = Math.floor(diff / oneDay); // Calculate the day of the year

    const dayIndex = dayOfYear % movies.length; // Use the day of the year to select a movie
    return movies[dayIndex];
  };

  // Function to save game state to localStorage (add date)
  const saveGameState = () => {
    const gameState = {
      guessedLetters,
      remainingGuesses,
      gameEnded,
      movieObj,
      isGameWon,
      isGameLost,
      lastPlayedDate: new Date().toISOString().split('T')[0]  // Save today's date
    };
    localStorage.setItem('hangmanGameState', JSON.stringify(gameState));
  };

  // Function to load game state from localStorage (with date check)
  const loadGameState = () => {
    const savedState = localStorage.getItem('hangmanGameState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      const savedDate = parsedState.lastPlayedDate;
      const todayDate = new Date().toISOString().split('T')[0];

      if (savedDate === todayDate) {
        setGuessedLetters(parsedState.guessedLetters);
        setRemainingGuesses(parsedState.remainingGuesses);
        setGameEnded(parsedState.gameEnded);
        setMovieObj(parsedState.movieObj);
        setGameWasLoaded(true); // Mark that the game was loaded
      } else {
        // If the game is from a previous day, clear it and load a new one
        clearGameState();
        const newMovie = getMovieOfTheDay();
        setMovieObj({ title: newMovie.title.toLowerCase(), link: newMovie.link });
        setGuessedLetters([]); // Reset guessed letters
        setRemainingGuesses(6); // Reset remaining guesses
        setGameEnded(false); // Reset game status
      }
    }
  };

  // Clear game state on new day or when starting a new game
  const clearGameState = () => {
    localStorage.removeItem('hangmanGameState');
  };

  useEffect(() => {
    const movieOfTheDay = getMovieOfTheDay();
    setMovieObj({ title: movieOfTheDay.title.toLowerCase(), link: movieOfTheDay.link });

    loadGameState(); // Load game state on page load

    // Store the current day when the app loads
    let currentDay = new Date().getDate();

    // Update the countdown timer every second and check for new day
    const intervalId = setInterval(() => {
      setCountdown(calculateCountdown());

      const now = new Date();
      const newDay = now.getDate();

      // Check if the day has changed (i.e., crossed midnight)
      if (newDay !== currentDay) {
        // Day has changed, start a new game
        clearGameState(); // Clear the game state
        const newMovie = getMovieOfTheDay(); // Get the new movie of the day
        setMovieObj({ title: newMovie.title.toLowerCase(), link: newMovie.link });
        setGuessedLetters([]); // Reset guessed letters
        setRemainingGuesses(6); // Reset remaining guesses
        setGameEnded(false); // Reset game status

        // Update the stored current day to the new day
        currentDay = newDay;
      }
    }, 1000);

    return () => clearInterval(intervalId); // Clean up on component unmount
  }, []);

  useEffect(() => {
    if (gameEnded && !isGameWon && !isGameLost) {
      // Prevent `totalTries` from incrementing on page load (on first render)
      return;
    }
    if (gameEnded && !gameWasLoaded) { // Only update if not loaded from localStorage
      // Update total tries only when the game ends, not when the game is loaded from localStorage
      setTotalTries(prevTotalTries => {
        const updatedTotalTries = prevTotalTries + 1;
        localStorage.setItem('totalTries', updatedTotalTries);
        return updatedTotalTries;
      });

      if (isGameWon) {
        setCurrentStreak(prevCurrentStreak => {
          const updatedCurrentStreak = prevCurrentStreak + 1;
          localStorage.setItem('currentStreak', updatedCurrentStreak);
          setBestStreak(prevBestStreak => {
            const newBestStreak = Math.max(updatedCurrentStreak, prevBestStreak);
            localStorage.setItem('bestStreak', newBestStreak);
            return newBestStreak;
          });
          return updatedCurrentStreak;
        });
      } else if (isGameLost) {
        setCurrentStreak(0);
        localStorage.setItem('currentStreak', 0);
      }

      saveGameState(); // Save game state after game ends
    }
  }, [gameEnded, isGameWon, isGameLost, gameWasLoaded]); // Added gameWasLoaded as a dependency

  useEffect(() => {
    if (isGameWon || isGameLost) {
      setGameEnded(true);
    }
  }, [isGameWon, isGameLost]);

  const handleGuess = (letter) => {
    if (!guessedLetters.includes(letter)) {
      setGuessedLetters([...guessedLetters, letter]);

      if (!movieObj.title.includes(letter)) {
        setRemainingGuesses(remainingGuesses - 1);
      }
    }
  };

  // Generate the shareable content
  const generateShareContent = () => {
    const clue = movieObj.title.split('').map(letter => {
      // Preserve spaces and special characters, hide all letters as '_'
      if (letter === ' ' || letter === "'" || letter === "?") {
        return letter;
      } else {
        return '_';
      }
    }).join(' ');

    return `I just played Movie Hangman! https://hangman.thisisbarry.com/ \n\nMovie: ${clue}`;
  };

  // Handle sharing the game result
  const handleShare = () => {
    const shareMessage = generateShareContent();
    navigator.clipboard.writeText(shareMessage).then(() => {
      alert("Game result copied to clipboard! You can share it now.");
    }, (err) => {
      alert("Failed to copy game result: " + err);
    });
  };

  return (
    <AppWrapper>
      <a href="https://www.thisisbarry.com/">
        <Logo src="https://www.thisisbarry.com/wp-content/uploads/SiteFiles/this-is-barry-logo-colour.png" alt="This Is Barry" />
      </a>
      <LinkWrapper>
        <a href="https://www.thisisbarry.com/">Films Explained</a>
      </LinkWrapper>
      <h1>Movie Hangman Game</h1>
      <GameBoard guessedLetters={guessedLetters} movie={movieObj.title} />
      {isGameWon && <h2>Congratulations! You won!</h2>}
      {isGameLost && (
        <div>
          <h2 style={{ color: 'red' }}>Game Over! The movie was:</h2>
          <h3 style={{ textTransform: 'uppercase' }}>{movieObj.title}</h3>
        </div>
      )}
      {(isGameWon || isGameLost) && (
        <ExplanationText>
          Read the <ExplanationLink href={movieObj.link} target="_blank">movie analysis</ExplanationLink> by Barry!
        </ExplanationText>
      )}
      {(isGameWon || isGameLost) && (
        <ShareButton onClick={handleShare}>Share Game Result</ShareButton>
      )}
      {(isGameWon || isGameLost) && (
        <CountdownWrapper>
          New Movie in: {countdown}
        </CountdownWrapper>
      )}
      {!isGameWon && !isGameLost && (
        <Keyboard guessedLetters={guessedLetters} handleGuess={handleGuess} />
      )}
      <p>Remaining Guesses: {remainingGuesses}</p>
      <Stickman wrongGuesses={6 - remainingGuesses} />
      <StatsWrapper>
        <Stat>
          {totalTries}
          <span>Total Tries</span>
        </Stat>
        <Stat>
          {currentStreak}
          <span>Current Streak</span>
        </Stat>
        <Stat>
          {bestStreak}
          <span>Best Streak</span>
        </Stat>
      </StatsWrapper>
    </AppWrapper>
  );
}

export default App;
